import React, { useEffect, useContext, useState } from "react";
import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";
import ToolLinks from "../components/ToolLinks/ToolLinks";
import SearchTools from "../components/SearchTools/SearchTools";

import { searchAITools as apiSearchAITools } from "../services/api";

import "./CouponCodePage.css";

const CouponCodePage = ({ data }) => {
  const [aiTools, setAITools] = React.useState([]);
  const [filteredAITools, setFilteredAITools] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [loadingCategories, setLoadingCategories] = React.useState(false);
  const [filterCategories, setFilterCategories] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const querySearchString = queryParams.get("q");
    if (querySearchString) {
      setSearchTerm(querySearchString);
    }
  }, []);

  // Debounce searchAITools function
  const debouncedSearchAITools = React.useCallback(async (term) => {
    try {
      setLoadingCategories(true);
      const data = await apiSearchAITools(term);
      if (data) {
        setAITools(data.tools);
        setFilteredAITools(data.tools);
        setFilterCategories(data.categories);
        setVisibleTools(20);
      }
      setLoadingCategories(false);
    } catch (e) {
      console.log(e);
      setLoadingCategories(false);
    }
  }, []);

  // Debounce the searchTerm
  React.useEffect(() => {
    const delay = setTimeout(() => {
      if (searchTerm) {
        debouncedSearchAITools(searchTerm);
      }
    }, 300); // Adjust the delay as needed

    return () => clearTimeout(delay);
  }, [searchTerm, debouncedSearchAITools]);

  useEffect(() => {
    const newFilteredTools =
      selectedCategories.length > 0
        ? aiTools.filter((tool) => {
            const toolCategories = tool.tags.split("\n");
            return selectedCategories.some((category) =>
              toolCategories.includes(category.trim())
            );
          })
        : aiTools;

    setFilteredAITools(newFilteredTools);
    setVisibleTools(20);
  }, [selectedCategories]);

  const [visibleToolsLength, setVisibleTools] = useState(20);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreCategories();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    const sentinelElement = document.querySelector("#sentinel");
    if (sentinelElement) {
      observer.observe(sentinelElement);
    }

    return () => {
      if (sentinelElement) {
        observer.unobserve(sentinelElement);
      }
    };
  }, []);

  const loadMoreCategories = () => {
    setVisibleTools((prevVisibleCategories) => prevVisibleCategories + 20);
  };

  const visibleTools = filteredAITools.slice(0, visibleToolsLength);

  return (
    <Page>
      <TopBar />
      <Section>
        <div>
          <ToolLinks
            showFilters
            tools={visibleTools}
            filterCategories={filterCategories}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </div>
        <div id="sentinel" style={{ height: "1px" }}></div>
      </Section>
      <Footer />
    </Page>
  );
};

export default CouponCodePage;

export const Head = () => {
  return (
    <SEO
      index
      follow
      title={"AI Product Reviews: The World's No. 1 Trusted AI Directory"}
      description={
        "Discover latest tools, unbiased reviews, user insights, guides, coupons & more. Your hub for all things AI. Explore now!"
      }
    />
  );
};
